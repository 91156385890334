import { NumberInput }    from "@mantine/core";
import { GoogleLogin }    from "@react-oauth/google";
import Link               from "next/link";
import { Fragment }       from "react";
import { useTranslation } from "react-i18next";

import { PrimaryButton }  from "_components/atoms/button";
import Icon               from "_components/atoms/icon";
import LoginInput         from "_components/atoms/mobile/loginInput";
import MobileBottomDrawer from "_components/atoms/mobileBottomDrawer";
import Typography         from "_components/atoms/typography";
import LoginBanner        from "_components/pages/auth/loginBanner";
import useStyles          from "_styles/molecules/interestialPopup";

const component = props => {
	const {
		otpLength,
		mobileLength,
		state,
		submitOtpLogin,
		submitForOtp,
		handleNumberChange,
		handleOtpChange,
		loginPopupStatus,
		handleBackButton,
		handleGoogleLogin,
		hideHeader,
		isActivatePage,
		toggleModalDisplay
	} = props;

	const { classes } = useStyles ( { isActivatePage }, { name: "auth" } );

	const { step, isSendingOtp, isResendOtp, count, otp, mobile } = state;

	const enableSendOtp = /^\d{10}$/.test ( mobile );
	const isOtpValid    = /^\d{6}$/.test ( otp );

	if ( isActivatePage ) {
		return (
			<LoginFormContent
				count              = { count }
				enableSendOtp      = { enableSendOtp }
				handleGoogleLogin  = { handleGoogleLogin }
				handleNumberChange = { handleNumberChange }
				handleOtpChange    = { handleOtpChange }
				hideHeader         = { hideHeader }
				isActivatePage     = { isActivatePage }
				isOtpValid         = { isOtpValid }
				isResendOtp        = { isResendOtp }
				isSendingOtp       = { isSendingOtp }
				mobile             = { mobile }
				mobileLength       = { mobileLength }
				otp                = { otp }
				otpLength          = { otpLength }
				state              = { state }
				step               = { step }
				submitForOtp       = { submitForOtp }
				submitOtpLogin     = { submitOtpLogin }
				toggleModalDisplay = { toggleModalDisplay }
			/>
		);
	}

	return (

		<MobileBottomDrawer
			isOpen  = { loginPopupStatus }
			onClose = { toggleModalDisplay }
		>
			<div className = { classes.slidingPopupContainerAuth }>

				<LoginBanner from = { "mobile" } />

				<div
					className = { step === 1 ?
						classes.closeSVGIconAuth :
						classes.backSVGIconAuth }
					onClick   = { () => {
						if ( step === 1 ) {
							toggleModalDisplay ();
						} else {
							handleBackButton ();
						}
					} }
				>

					{ step === 1 ? (
						<Icon
							className = { classes.svgIconDark }
							name      = "cross"
						/>
					) :
						(
							<Fragment>
								<Icon className = { classes.svgIconDark }
									name           = "backArrow"
								/>

								<p className = { classes.otpVerifyHeading }>OTP Verification</p>

								<Icon className = { classes.svgIconDark }
									name           = "cross"
								/>
							</Fragment>
						)}
				</div>

				<LoginFormContent
					count              = { count }
					enableSendOtp      = { enableSendOtp }
					handleGoogleLogin  = { handleGoogleLogin }
					handleNumberChange = { handleNumberChange }
					handleOtpChange    = { handleOtpChange }
					hideHeader         = { hideHeader }
					isActivatePage     = { isActivatePage }
					isOtpValid         = { isOtpValid }
					isResendOtp        = { isResendOtp }
					isSendingOtp       = { isSendingOtp }
					mobile             = { mobile }
					mobileLength       = { mobileLength }
					otp                = { otp }
					otpLength          = { otpLength }
					state              = { state }
					step               = { step }
					submitForOtp       = { submitForOtp }
					submitOtpLogin     = { submitOtpLogin }
					toggleModalDisplay = { toggleModalDisplay }
				/>
			</div>
		</MobileBottomDrawer>

	);
};

export default component;

function LoginFormContent ( {
	count,
	handleGoogleLogin,
	handleNumberChange,
	handleOtpChange,
	hideHeader,
	isActivatePage,
	isResendOtp,
	isSendingOtp,
	mobile,
	mobileLength,
	otp,
	step,
	submitForOtp,
	state,
	submitOtpLogin,
	toggleModalDisplay
} ) {
	const { classes } = useStyles ( {}, { name: "login-popup" } );
	const { t }       = useTranslation ();

	return (
		<div className = { classes.loginContianer }>
			{step === 1 && !hideHeader && (
				<Typography
					className = { classes.titleText }
					title     = { step === 1 && t ( "popup_login" ) }
				/>
			)}

			{step === 1 && (
				<div>
					<LoginInput
						errorLogin        = { state.mobileNumberError }
						errorMsgLogin     = { state.mobileNumberError }
						handleLoginChange = { handleNumberChange }
						inputLength       = { mobileLength }
						mobile            = { mobile }
					/>

					<div className = { classes.dialogActions }>
						<PrimaryButton
							buttonTextClassName = { classes.buttonTextClassName }
							className           = { mobile.toString ().length !== 10 ? classes.submitMobileDisabled : classes.submitMobileButton }
							fullWidth           = { true }
							onClick             = { submitForOtp }
							size                = "xl"
							title               = { isSendingOtp ? "" : "Continue" }
							variant             = "contained"
						>
						</PrimaryButton>

					</div>

					<div className = { classes.alternateContainer }>
						<div className = { classes.dashes }>
							&#8212;
						</div>

						<div className = { classes.orText }>
							or
						</div>

						<div className = { classes.dashes }>
							&mdash;
						</div>
					</div>

					<div className = { classes.googleButtonContainer }>

						<GoogleLogin
							logo_alignment = "left"
							onSuccess      = { handleGoogleLogin }
							shape          = "pill"
							theme          = { "filled_blue" }
							width          = "100%"
						/>
					</div>

					{isActivatePage ?
						null : (
							<div className = { classes.dialogFooter }>
								<Link className = { classes.dialogFooterText }
									href           = "/terms-and-conditions"
									onClick        = { toggleModalDisplay }
									target         = "_blank"
								>
									Terms & Conditions

								</Link>

								<span className = { classes.ellipse }></span>

								<Link className = { classes.dialogFooterText }
									href           = "/privacy-policy"
									onClick        = { toggleModalDisplay }
									target         = "_blank"
								>
									Privacy Policy
								</Link>
							</div>
						)}
				</div>
			)}

			{( step === 2 ) && (
				<div>
					<div className = { classes.dialogStyles }>
						{isActivatePage ? (
							<Typography
								className = { classes.otpHeading }
								title     = { "OTP Verification" }
							/>
						) : null}

						<Typography
							title = { "A 6 digit OTP has been sent to " }
						/>

						<Typography
							title = { `+91 ${ mobile }` }
						/>

						<Typography
							title = { "Enter it below to continue" }
						/>

						<NumberInput
							autoFocus
							hideControls
							classNames = { { root: classes.pinRoot, input: classes.pinInput, error: classes.pinError } }
							errorMsg   = { Boolean ( state.otpError ) }
							id         = "otp"
							inputProps = { { "data-testid": "enter-otp" } }
							maxLength  = { 6 }
							onChange   = { handleOtpChange }
							// placeholder = "&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
							value      = { otp }
						/>

						<div className = { classes.otpMessageContainer }>
							<Typography
								className = { classes.otpError }
								color     = "red"
								title     = { state?.otpError ? state?.otpErrorMsg || "" : null }
							/>
						</div>

						<div className = { classes.dialogActionsVerifyButton }>
							<PrimaryButton
								buttonTextClassName = { classes.buttonTextClassName }
								className           = { otp.toString ().length !== 6 ? classes.submitMobileDisabled : classes.submitMobileButton }
								fullWidth           = { true }
								onClick             = { submitOtpLogin }
								size                = "xl"
								title               = { t ( "login_verify_otp" ) }
								variant             = "contained"
							>
							</PrimaryButton>
						</div>

						<div style = { { display: "flex", alignItems: "center", justifyContent: "center", fontSize: "1.4rem" } }>
							<div className = { classes.resendText }>
								<div className = { !isResendOtp ? classes.textInactive : classes.textActive }
									onClick       = { isResendOtp ? submitForOtp : () => { } }
								>
									{t ( "login_resend_otp" )}
								</div>
							</div>

							<div className = { classes.resendTimer }>
								<span
									className = { isResendOtp ? classes.resendBtnEnabled : classes.resendBtn }
									onClick   = { isResendOtp ? submitForOtp : () => { } }
								>

								</span>

								{!isResendOtp && (
									<span>
										<span className = { classes.resendTextFont }>in</span>

										<span className = { classes.timerFont }>

											{count}
										</span>

										<span className = { classes.resendTextFont }>Sec</span>
									</span>
								)}
							</div>
						</div>
					</div>

				</div>
			)}
		</div>
	);
}
