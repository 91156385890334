import { NumberInput }    from "@mantine/core";
import React              from "react";
import { useTranslation } from "react-i18next";

import Icon from "_components/atoms/icon";

import useStyles from "./styles";

const component = props => {
	const {
		inputLength,
		mobile,
		handleLoginChange,
		errorLogin
	} = props;

	const { classes } = useStyles ( undefined, { name: "styles" } );
	const { t }       = useTranslation ();

	return (
		<div className = { classes.dialogStyles }>
			<div className = { classes.dialogText }>
				{t ( "enter_mob_no" )}
			</div>

			<NumberInput
				hideControls
				autoComplete     = "off"
				classNames       = { {
					root    : classes.numberInputRoot,
					input   : classes.numberInputInput,
					section : classes.numberInputIcon
				} }
				error            = { errorLogin ? true : false }
				id               = "mobile"
				leftSection      = { (
					<div className = { classes.phoneLeftIcon }>
						<Icon
							sameInBothTheme
							className = { classes.flagIndiaIcon }
							name      = "flagIndia"
						/>

						<div>+91</div>
					</div>
				) }
				leftSectionWidth = { 140 }
				onChange         = { handleLoginChange }
				onInput          = { e => {
					e.target.value = Math.max ( 0, parseInt ( e.target.value ) ).toString ().slice ( 0, inputLength );
				} }
				placeholder      = "Enter Phone Number"
				radius           = "lg"
				size             = "xl"
				type             = "number"
				value            = { mobile }
			/>
		</div>
	);
};

export default component;
