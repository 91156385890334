import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => {
	return {
		input: {
			fontSize         : "1.6rem",
			"&::placeholder" : {
			  color: theme.other.darkMode ? "white" : "rgba(0, 0, 0, 0.8)"
			},
			color: theme.other.darkMode ? "white" : "rgba(0, 0, 0, 0.8)"
		},
		root: {

			background   : theme.other.colors.input.primary,
			borderRadius : "10px",
			marginTop    : "6px !important",
			borderColor  : theme.other.colors.background.primary,
			color        : "#fff"

		},
		notchedOutline: {
			borderWidth  : "1px",
			borderRadius : "10px",
			borderColor  : theme.other.colors.background.primary
		},
		cssFocused: {

			borderColor: "#126bd5 !important"

		},

		dialogActions: {
			padding: "0px 23px"
		},
		dialogStyles: {
			overflowY : "hidden !important",
			padding   : "3px 24px 10px"
		},
		dialogFooterText: {
			display        : "inline",
			color          : "#126bd5",
			textDecoration : "none",
			fontSize       : "11px"
		},
		dialogFooter: {
			textAlign : "center",
			margin    : "17px 0px"
		},
		ellipse: {
			height          : "4px",
			width           : "4px",
			backgroundColor : "#6e6e6e",
			borderRadius    : "50%",
			display         : "inline-block",
			position        : "relative",
			top             : "-4px",
			margin          : "0px 10px"
		},
		dialogContainer: {
			maxWidth : "400px",
			margin   : "auto"
		},
		loginDialog: {
			width    : "100%",
			margin   : "48px 0",
			position : "absolute",
			maxWidth : "400px"
		},
		leftArrowIcon: {
			height       : "16px",
			width        : "14px",
			paddingRight : "5px"
		},
		headerTitle: {
			padding: "16px 24px 0px !important"
		},
		sendOTPButton: {
			padding  : "6px 10px",
			color    : "rgba(255, 255, 255, 1)",
			fontSize : "16px"

		},
		submitMobileButton: {
			fontSize        : "1.4rem",
			height          : "42px",
			width           : "100%",
			color           : "rgba(255, 255, 255, 1)",
			backgroundImage : "linear-gradient(to left, #439aff 11%, #126bd5 92%)",
			textTransform   : "none",
			boxShadow       : "none !important",
			borderRadius    : "8px !important"
		},
		submitMobileDisabled: {
			fontSize        : "1.4rem",
			height          : "42px",
			width           : "100%",
			color           : "rgba(255, 255, 255, 1)",
			backgroundImage : "linear-gradient(94deg, #126bd5 3%, #439aff 93%)",
			textTransform   : "none",
			boxShadow       : "none !important",
			filter          : "opacity(0.7)"
		},
		verifyNowButton: {
			padding  : "6px 10px",
			color    : "rgba(255, 255, 255, 1)",
			fontSize : "16px"
		},
		submitMobileButtonActive: {
			background: "theme.other.paletteNew.mainGradient"
		},
		backdropOpacity: {
			backgroundColor: "rgba(0, 0, 0, 0.8)"
		},
		backButton: {
			color      : "#fff",
			textAlign  : "right",
			fontWeight : "500",
			fontSize   : "20px",
			padding    : "10px 20px 0"
		},
		otpInput: {
			letterSpacing : "25px",
			textAlign     : "center"
		},
		otpError: {
			color: "red"
		},
		otpMessageContainer: {
			display        : "flex",
			alignItems     : "center",
			justifyContent : "space-between",
			position       : "absolute"
		},
		heading: {
			fontSize   : "25px",
			fontWeight : "500",
			textAlign  : "center",
			padding    : "10px",
			marginTop  : "40px",
			color      : "white"
		},
		formContainer: {
			color: "white"
		},
		goBack: {
			padding      : "14px 24px",
			borderBottom : "1px solid #e8e8e8",
			fontSize     : "13px",
			color        : "#5c5c5c",
			cursor       : "pointer",
			display      : "flex"
		},
		inputDisableSpinner: {
			"& input": {
				caretColor : theme.other.paletteNew.primaryDarkBlue,
				color      : `${ theme.other.colors.text.primary } !important`
			},
			"& input[type=number]": {
				mozAppearance: "textfield"
			},
			"& input[type=number]::-webkit-outer-spin-button": {
				webkitAppearance : "none",
				margin           : 0
			},
			"& input[type=number]::-webkit-inner-spin-button": {
				webkitAppearance : "none",
				margin           : 0
			},
			"&::placeholder": {
				fontStyle: "italic"
			},
			"&$cssFocused $notchedOutline": {
				borderColor: "#126bd5 !important"
			}
		},
		resendBtn: {
			color  : "#5c5c5c",
			cursor : "not-allowed"
		},
		resendBtnEnabled: {
			color  : "#2283f6",
			cursor : "pointer"
		},
		label: {
			color: "red"
		},
		resendText: {
			margin         : "10px",
			display        : "flex",
			justifyContent : "center",
			fontSize       : "13px"

		},
		textInactive: {
			color: "rgba(18, 107, 213, 0.5) !important"
		},
		textActive: {
			color    : "#126bd5 !important",
			fontSize : "13px !important"
		},

		dialogText: {
			fontSize : "18px",
			color    : theme.other.colors.text.secondary,
			margin   : "5px 0"
		},
		resendTimer: {
			display        : "flex",
			justifyContent : "center",
			alignItems     : "center",
			marginTop      : "-26px",
			fontSize       : "calc(0.75rem + 0.5vw)",
			height         : "24px"
		},
		resendTextFont: {
			padding  : "0 3px",
			color    : "rgba(18, 107, 213, 0.5)",
			fontSize : "calc(0.75rem + 0.5vw)"
		},
		timerFont: {
			color    : "rgba(18, 107, 213, 0.5)",
			fontSize : "calc(0.75rem + 0.5vw)"
		},
		rightArrowIcon: {
			height   : "16px",
			width    : "14px",
			position : "absolute",
			right    : "12px",
			top      : "13px"
		},
		svgIconDark: {
			filter: "invert(1)"
		},
		inputAdornmentTextStyles: {
			marginLeft : "5px",
			fontSize   : "1.6rem",
			color      : `${ theme.other.colors.text.primary } !important`
		},
		flagIndiaIcon:
		{
			height : "2.5rem",
			width  : "2.5rem"
		},
		phoneLeftIcon: {
			display        : "flex",
			alignItems     : "center",
			justifyContent : "space-evenly",
			borderRight    : `1px solid ${ theme.other.colors.border.primary }`,
			padding        : "0 1rem"
			// width          : "85%"
		},
		numberInputRoot: {
			fontSize : "1.6rem",
			margin   : "1rem 0"

		},
		numberInputInput: {
			fontSize        : "1.6rem",
			paddingLeft     : "9rem !important",
			height          : "2rem",
			padding         : "2.5rem 0",
			borderWidth     : "2px",
			backgroundColor : theme.other.colors.background.primary
		},
		numberInputIcon: {
			width   : "9rem",
			padding : "0.5rem",
			color   : theme.other.colors.text.primary

		}
	};
} );
